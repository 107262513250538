import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import vue3GoogleLogin from 'vue3-google-login'

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueGoogleMaps from '@fawmi/vue-google-maps'

import ApexCharts from 'apexcharts'

import "@/core/plugins/prismjs";
const app = createApp(App);
const gAuthOptions = { clientId: '378414351095-b0geh6f47lvb0nm4a0e8tr5brj2tomu4.apps.googleusercontent.com', scope: 'email profile openid' }

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAz5SWFnZMsrZ2w58Os1Y6kYFc1Y7BrCKc',
        libraries: "places"
    }
});
app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
app.use(vue3GoogleLogin, gAuthOptions)
app.use(i18n);

app.mount("#app");
