import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Stat {
  id: number,
  label: string,
  value: number,
  type: string

}

@Module
export default class AdvertModule extends VuexModule {
  errors: Array<string> = [];
  stats: Array<Stat> = [];

  /**
   * Get current user object
   * @returns Adverts
   */
  get currentStats(): Array<Stat> {
    return this.stats;
  }

  @Action
  [Actions.GET_STATS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/v1/stats/",)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          resolve();
        });
    });
  }


  @Mutation
  [Mutations.SET_STATS](stats) {
    this.stats = stats;
  }
}
