import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Advert {
  Title : string
  Summary : string
  Space : string
  guest_access : string
  neighbourhood : string
  getting_around : string
  other_things : string
  interaction : string
}
export interface Advertv2 {
  Summary : string
}

@Module
export default class AdvertModule extends VuexModule {
  errors:Array<string> = [];
  adverts_v1: Array<Advert> = [];
  adverts_v2: Array<Advert> = [];
  adverts_v3: Array<Advert> = [];
  playground: Array<Advert> = [];


    /**
   * Get current user object
   * @returns Adverts
   */
     get currentResultsPlayground(): Array<Advert> {
      return this.playground;
    }
  /**
   * Get current user object
   * @returns Adverts
   */
  get currentResultsV1(): Array<Advert> {
    return this.adverts_v1;
  }
    /**
   * Get current user object
   * @returns Adverts
   */
     get currentResultsV2(): Array<Advert> {
      return this.adverts_v2;
    }
    /**
   * Get current user object
   * @returns Adverts
   */
     get currentResultsV3(): Array<Advert> {
      return this.adverts_v3;
    }
  @Action
  [Actions.GET_ADVERT_V1](form) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/v1/advert/generate/", form)
        .then(({ data }) => {
          console.log("data : ",data);
          this.context.commit(Mutations.SET_ADVERT_V1, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          resolve();
        });
    });
  }

  @Action
  [Actions.GET_ADVERT_V2](form) {
  
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/v1/advert/generate/openai", form)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADVERT_V2, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          resolve();
        });
    });
  }
  @Action
  [Actions.GET_ADVERT_V3](form) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/v1/advert/generate/v3", form)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADVERT_V3, data);
          resolve();
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PLAYGROUND](form) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/v1/advert/generate/playground", form)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLAYGROUND, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.detail);
          resolve();
        });
    });
  }

  @Mutation
  [Mutations.SET_PLAYGROUND](adverts) {
    this.playground = adverts;
  }

  @Mutation
  [Mutations.SET_ADVERT_V1](adverts) {
    this.adverts_v1 = adverts;
  }

  @Mutation
  [Mutations.SET_ADVERT_V2](adverts) {
    this.adverts_v2 = adverts;
  }
  @Mutation
  [Mutations.SET_ADVERT_V3](adverts) {
    this.adverts_v3 = adverts;
  }

}
